import { subscriptionFrequenciesMap } from "./data/schemas/subscription";

export const formatFrequency = (
  invoiceType,
  frequency,
  subscriptionEnd,
  subscriptionPaymentCount
) => {
  if (invoiceType === "subscription")
    return {
      ...subscriptionFrequenciesMap[frequency].data,
      ...(subscriptionEnd === "count" && {
        paymentCount: Number(subscriptionPaymentCount),
      }),
    };
};

export const getFrequencyData = (interval, intervalCount = 1) => {
  return Object.entries(subscriptionFrequenciesMap).find(
    ([, freq]) =>
      freq.data.interval === interval &&
      freq.data.interval &&
      freq.data.intervalCount === (intervalCount || 1)
  )?.[0];
};

export const getFrequencyLabel = (interval, intervalCount = 1) => {
  const key = getFrequencyData(interval, intervalCount);
  if (!key) return "";
  return subscriptionFrequenciesMap[key]?.title;
};

export const displayPaymentString = (
  payment,
  couponAmount = "",
  couponUnit = ""
) => {
  const paymentValue = `${(payment.total / 100).toLocaleString("en-US", {
    style: "currency",
    currency: payment.currency,
  })}`;

  let paymentRecurring = "";
  if (payment.recurring) {
    const { interval, intervalCount } = payment.recurring;

    paymentRecurring = `/${getFrequencyLabel(interval, intervalCount)}`;
  }

  if (couponAmount && couponUnit) {
    if (couponUnit == "%") {
      return `${paymentValue}${paymentRecurring} - ${couponAmount}${couponUnit} off to be applied`;
    } else {
      return `${paymentValue}${paymentRecurring} - ${couponAmount} ${couponUnit} off to be applied`;
    }
  } else {
    return `${paymentValue}${paymentRecurring}`;
  }
};

export const displayProductString = (product) => {
  if (!product) return;
  return `${(product.payment.amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: product.payment.currency,
  })}`;
};

export const displayItemAmountString = (
  itemAmount,
  currency,
  couponValue = "",
  couponUnit = "",
  extraSettings = {}
) => {
  const settings = {
    style: "currency",
    currency: currency,
    ...extraSettings,
  };
  if (itemAmount !== 0 && !itemAmount) return;
  if (couponValue && couponUnit) {
    if (couponUnit == "%") {
      return `${itemAmount.toLocaleString(
        "en-US",
        settings
      )} - ${couponValue}${couponUnit} off to be applied`;
    } else {
      return `${itemAmount.toLocaleString(
        "en-US",
        settings
      )} - ${couponValue} ${couponUnit} off to be applied`;
    }
  } else {
    return `${itemAmount.toLocaleString("en-US", settings)}`;
  }
};

export const displayTaxTypeString = (taxType, hidePreposition = false) => {
  if (!taxType?.name) return "";
  if (taxType?.name === "No tax") return "No tax (0%)";

  const withTax = taxType.inclusive ? "including" : "excluding";
  const preposition = hidePreposition ? "" : withTax;

  return `${preposition} ${taxType.name} (${taxType.percentage}%)`;
};

export const displayCouponAmountOff = (value, unit) => {
  if (unit === "%") return `${value}${unit}`;
  return displayItemAmountString(value, unit);
};
